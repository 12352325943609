import React from "react";

import ContactsConst from "../constants/contacts";

const Contacts = () => {
	return (
		<div className="contacts-info">
			<p>Если остались вопросы вы можете задать их:</p>
			<a href={ContactsConst.phoneLink} className="contacts-info__phone">
				{ContactsConst.phone}
			</a>
			<a href={`mailto:${ContactsConst.email}`} className="contacts-info__email">
				{ContactsConst.email}
			</a>
		</div>
	);
};

export default Contacts;
