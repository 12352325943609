import * as Types from "../types";

import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
import * as ApolloReactComponents from "@apollo/client/react/components";
import * as ApolloReactHoc from "@apollo/client/react/hoc";
import gql from "graphql-tag";
import * as React from "react";
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type QrCodeLinkQueryVariables = Types.Exact<{
	qrCode: Types.Scalars["String"];
}>;

export type QrCodeLinkQuery = { __typename?: "Query" } & {
	QrCodeLink: { __typename?: "DataLinkedResult" } & Pick<
		Types.DataLinkedResult,
		"Link" | "Name" | "PersonType" | "OrgType" | "ContactType"
	>;
};

export const QrCodeLinkDocument = gql`
	query QrCodeLink($qrCode: String!) {
		QrCodeLink(qrCode: $qrCode) {
			Link
			Name
			PersonType
			OrgType
			ContactType
		}
	}
`;
export type QrCodeLinkComponentProps = Omit<
	ApolloReactComponents.QueryComponentOptions<QrCodeLinkQuery, QrCodeLinkQueryVariables>,
	"query"
> &
	({ variables: QrCodeLinkQueryVariables; skip?: boolean } | { skip: boolean });

export const QrCodeLinkComponent = (props: QrCodeLinkComponentProps) => (
	<ApolloReactComponents.Query<QrCodeLinkQuery, QrCodeLinkQueryVariables> query={QrCodeLinkDocument} {...props} />
);

export type QrCodeLinkProps<TChildProps = {}, TDataName extends string = "data"> = {
	[key in TDataName]: ApolloReactHoc.DataValue<QrCodeLinkQuery, QrCodeLinkQueryVariables>;
} &
	TChildProps;
export function withQrCodeLink<TProps, TChildProps = {}, TDataName extends string = "data">(
	operationOptions?: ApolloReactHoc.OperationOption<
		TProps,
		QrCodeLinkQuery,
		QrCodeLinkQueryVariables,
		QrCodeLinkProps<TChildProps, TDataName>
	>
) {
	return ApolloReactHoc.withQuery<
		TProps,
		QrCodeLinkQuery,
		QrCodeLinkQueryVariables,
		QrCodeLinkProps<TChildProps, TDataName>
	>(QrCodeLinkDocument, {
		alias: "qrCodeLink",
		...operationOptions
	});
}

/**
 * __useQrCodeLinkQuery__
 *
 * To run a query within a React component, call `useQrCodeLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useQrCodeLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQrCodeLinkQuery({
 *   variables: {
 *      qrCode: // value for 'qrCode'
 *   },
 * });
 */
export function useQrCodeLinkQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<QrCodeLinkQuery, QrCodeLinkQueryVariables>
) {
	return ApolloReactHooks.useQuery<QrCodeLinkQuery, QrCodeLinkQueryVariables>(QrCodeLinkDocument, baseOptions);
}
export function useQrCodeLinkLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<QrCodeLinkQuery, QrCodeLinkQueryVariables>
) {
	return ApolloReactHooks.useLazyQuery<QrCodeLinkQuery, QrCodeLinkQueryVariables>(QrCodeLinkDocument, baseOptions);
}
export type QrCodeLinkQueryHookResult = ReturnType<typeof useQrCodeLinkQuery>;
export type QrCodeLinkLazyQueryHookResult = ReturnType<typeof useQrCodeLinkLazyQuery>;
export type QrCodeLinkQueryResult = ApolloReactCommon.QueryResult<QrCodeLinkQuery, QrCodeLinkQueryVariables>;
