import { navigate } from "gatsby";
import LZString from "lz-string";
import QRCode from "qrcode.react";
import React, { useEffect } from "react";
import Contacts from "../components/contacts";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { useQrCodeLinkLazyQuery } from "../graphql/query/QrCodeLink.generated";

interface IButtonLinkProps {
	link?: string | null;
}

interface ILinkPageProps {
	"*": string;
}

interface ILinkData {
	loginProfileType: string;
	QRCodeType: number;
}

const ButtonLink = (props: IButtonLinkProps) => {
	if (!props.link) {
		return null;
	}
	return (
		<div className="button-link-container">
			<p>
				Если вы открыли этот документ на мобильном устройстве или не можете сканировать QR код нажмите
				[Перейти].
			</p>
			<a className="button-link" href={props.link}>
				Перейти
			</a>
		</div>
	);
};

const LinkPage: React.ComponentType<ILinkPageProps> = (props) => {
	const linkJSON = LZString.decompressFromEncodedURIComponent(props["*"]);
	const [getQrCode, { data }] = useQrCodeLinkLazyQuery();

	let linkData: ILinkData | null = null;

	useEffect(() => {
		if (!linkJSON || !linkData) {
			navigate("/");
			return;
		}
		getQrCode({
			variables: {
				qrCode: linkJSON
			}
		});
	}, [linkJSON, linkData]);

	if (!linkJSON) {
		return null;
	}

	try {
		linkData = JSON.parse(linkJSON);
	} catch (e) {
		console.log("Error parsing link data:", e);
	}
	if (!linkData) {
		return null;
	}

	let accessText;
	switch (linkData.loginProfileType) {
		case "Owner": {
			accessText = "полный доступ к профилю";
			break;
		}
		case "Child": {
			accessText = "доступ к профилю ребенка";
			break;
		}
		default: {
			accessText = "ограниченный доступ к профилю";
		}
	}

	const h1Text =
		linkData.QRCodeType === 1
			? `Вам предоставлен ${accessText} "${data?.QrCodeLink?.Name ||
					"Имя не указано"}" в приложении "Стань чемпионом"`
			: `"${data?.QrCodeLink?.Name ||
					"Пользователь"}" добавил вас в свой список контактов в приложении "Стань чемпионом"`;

	return (
		<Layout>
			<SEO title="Стань чемпионом!" />

			<div className="container link-page">
				<div className="link-head">
					<h1>{h1Text}</h1>
					<p className="subtitle">
						{linkData.QRCodeType === 1
							? "Для получения доступа сканируйте QR код камерой телефона"
							: "Чтобы подтвердить связь контакта с вашим профилем сканируйте QR код камерой телефона"}
					</p>
					{data?.QrCodeLink?.Link && (
						<div className="qr-code-container">
							<QRCode value={data.QrCodeLink.Link} size={300} level="M" className={"qr-code"} />
						</div>
					)}
					<p>
						Вы перейдете в магазин приложений (AppStore или Google Play) для установки “Стань чемпионом!”.
						После установки сканируйте QR еще раз для
						{linkData.QRCodeType === 1 ? " получения доступа" : " связывания контакта"}.
					</p>
					<p>
						Если приложение у Вас установлено, Вы сразу перейдете к
						{linkData.QRCodeType === 1 ? " получению доступа" : " связыванию контакта"}.
					</p>
					<ButtonLink link={data?.QrCodeLink?.Link} />
					<p className="confidential-info">
						Информация является конфиденциальной и сгенерирована специально для вашего профиля. Не
						передавайте эту информацию третьим лицам во избежание компрометации данных профиля!
					</p>
					<Contacts />
				</div>
			</div>
		</Layout>
	);
};

export default LinkPage;
